<template>
  <a-drawer :title="title" width="720" :body-style="{ paddingBottom: '80px' }" placement="right" :closable="false"
  :maskClosable="false" @close="onClose" :visible="visible">
    <a-spin :spinning="isLoading">
      <a-form-model ref="ruleForm" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-row :gutter="20">
          <a-col :span="24">
            <a-form-model-item label="文章标题" prop="blogTitle">
              <a-input v-model="form.blogTitle" placeholder="请输入文章标题" allow-clear>
              </a-input>
            </a-form-model-item>
          </a-col>
        </a-row>

        <a-row :gutter="20">
          <a-col :span="24">
            <a-form-model-item label="页面描述" prop="metaDescription">
              <a-input v-model="form.metaDescription" placeholder="请输入页面描述" allow-clear>
              </a-input>
            </a-form-model-item>
          </a-col>
        </a-row>

        <a-row :gutter="20">
          <a-col :span="24">
            <a-form-model-item label="文章分类" prop="blogCategory">
              <a-select v-model="form.blogCategory">
                <a-select-option v-for="(item, index) in dictList" :key="index" :value="item.value">
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
        </a-row>

        <a-row :gutter="20">
          <a-col :span="24">
            <a-form-model-item label="文章图片" prop="name">
              <!-- <upload ref="uploadComponent" :imageList="form.blogImage" /> -->
              <a-spin size="large" :spinning="isLoadingUpload" tip="文件上传中……">
                <a-upload list-type="picture-card" :file-list="fileList" multiple :customRequest="customRequest"
                  @preview="handlePreview" @change="handleChange" :before-upload="beforeUpload" :remove="handleRemove">
                  <div v-if="fileList.length < 9">
                    <a-icon type="plus" />
                    <div class="ant-upload-text">
                      上传
                    </div>
                  </div>
                </a-upload>
                <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
                  <img alt="example" style="width: 100%" :src="previewImage" />
                </a-modal>
              </a-spin>
            </a-form-model-item>
          </a-col>
        </a-row>

        <!-- 文章详情 -->
        <a-row :gutter="20">
          <a-col :span="24">
            <MyEditor ref="editorComponent" v-model='content' @editorContent='contentChange' :editData="content" />
          </a-col>
        </a-row>
      </a-form-model>
    </a-spin>
    <div :style="{
      position: 'absolute',
      right: 0,
      bottom: 0,
      width: '100%',
      borderTop: '1px solid #e9e9e9',
      padding: '10px 16px',
      background: '#fff',
      textAlign: 'right',
      zIndex: 1,
    }">
      <a-button :style="{ marginRight: '8px' }" @click="onClose">取消</a-button>
      <a-button type="primary" @click="handleSubmit" :disabled="isLoading"
        :icon="isLoading ? 'loading' : ''">确定</a-button>
    </div>
  </a-drawer>
</template>

<script>
import { blogInsertUrl, blogUpdateUrl, dictListUrl } from "@/utils/url";
// import upload from "@/components/Upload";
import MyEditor from "@/components/MyEditor";

export default {
  components: {
    // upload, 
    MyEditor
  },
  data() {
    return {
      visible: false,
      title: "",
      labelCol: { span: 3 },
      wrapperCol: { span: 21 },
      isLoading: false,
      dictList: [], // 字典列表

      form: {}, // 表单数据
      content: '', // 编辑器数据


      previewVisible: false, // 图片预览框
      isLoadingUpload: false, // 上传文件loading
      previewImage: '',
      fileList: [],
      filePathList: [], // 文章图片文件路径列表
      fileCodeList: [], // 文章图片存入code

      // 校验
      rules: {
        blogTitle: [
          { required: true, message: "请填写文章标题！", trigger: "blur" },
        ],
        // blogCategory: [
        //   { required: true, message: "请选择文章分类！", trigger: "change" },
        // ],
        blogImage: [
          { required: true, message: "请上传文章图片！", trigger: "change" },
        ],
        blogContent: [
          { required: true, message: "请填写文章描述！", trigger: "blur" }
        ]
      },
    };
  },
  methods: {
    // 新增
    add() {
      this.title = "新增文章";

      console.log('执行前==content', this.content);
      this.content = '';
      console.log('执行后==content', this.content);

      this.fileList = []; // 清空上传的文件列表
      this.form = {};
      this.$nextTick(() => {
        this.$refs.editorComponent.updateContent(''); // 清空富文本编辑器的内容
        this.content = '';
        this.$refs.editorComponent.token = this.$cookie.getCookie('token');
      });
      

      this.visible = true;

      this.queryDictList(); // 查询词典列表
    },
    // 编辑
    edit(record) {
      this.title = "编辑文章";
      this.visible = true;

      this.fileList = JSON.parse(record.blogImage); // 图片处理
      this.content = record.blogContent; // 富文本处理

      this.form = record;
      this.form.blogContent = record.blogContent;

      this.$nextTick(() => {
        this.$refs.editorComponent.updateContent(record.blogContent);
      });

      this.queryDictList(); // 查询词典列表
    },
    // 关闭抽屉
    onClose() {
      // this.$refs.ruleForm.resetFields();
      this.content = '';
      this.fileList = []; // 清空上传的文件列表
      this.$nextTick(() => {
        this.$refs.editorComponent.updateContent(''); // 清空富文本编辑器的内容
        // this.content = '';
      });
      this.form = {};
      this.visible = false;
    },
    handleRemove(data) {
      const index = this.fileList.findIndex(file => file.uid === data.uid);
      if (index !== -1) {
        this.fileList.splice(index, 1);
      }
    },
    beforeUpload(file) {
      const { type, size } = file;
      // const limitType = type === 'image/jpeg' || type === 'image/png';

      const allowedTypes = ['image/jpeg', 'image/png', 'image/webp'];
      const limitType = allowedTypes.includes(type);

      if (!limitType) {
        this.$message.error('请上传 JPG、PNG 格式图片!');
      }
      const limitSize = size / 1024 / 1024 < 2;
      if (!limitSize) {
        this.$message.error('图片不可大于 2MB!');
      }
      return limitType && limitSize;
    },
    // 自定义文件上传
    customRequest(data) {
      this.isLoadingUpload = true;

      const formData = new FormData();
      formData.append("file", data.file);
      const token = this.$cookie.getCookie("token");

      formData.append("X-Access-Token", token);

      let headers = { "Content-Type": "multipart/form-data" };

      this.$axios.post("file/upload", formData, { headers }).then(res => {
        if (res.success) {
          this.$message.success(res.message);
          let fileUrl = `${process.env.VUE_APP_API_BASE_URL}/file${res.result.filePath}/${res.result.fileName}.${res.result.fileSuffix}`;
          console.log("查看res::", res);

          this.fileList.push({
            uid: res.result.id,
            name: res.result.fileName,
            // status: 'done',
            url: fileUrl
          })

          this.filePathList.push(`${res.result.filePath}/${res.result.fileName}.${res.result.fileSuffix}`);
          this.fileCodeList.push(res.result.fileCode);
        }
      }).finally(() => {
        this.isLoadingUpload = false;
      });
    },
    handleCancel() {
      this.previewVisible = false;
    },
    async handlePreview(file) {
      console.log("查看handlePreview::", file.url)
      console.log("file.preview::", file.preview)

      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
        console.log("file.originFileObj::", file.originFileObj)
      }
      this.previewImage = file.url || file.preview;
      this.previewImage = file.url;
      console.log("this.previewImage::", this.previewImage)
      this.previewVisible = true;
    },
    handleChange({ fileList }) {
      // this.fileList = fileList;
    },
    // 查询字典列表
    queryDictList() {
      let params = {
        category: "blog"
      };

      this.$axios.get(dictListUrl, { params }).then(res => {
        if (res.success) {
          this.dictList = res.result;
        }
      });
    },
    contentChange(data) {
      console.log("编辑器数据::", data)
      this.content = data
    },
    handleSubmit() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.isLoading = true;

          this.form.blogContent = this.content;
          // console.log('查看文件上传：', this.fileList);
          this.form.blogImage = JSON.stringify(this.fileList);
          
          // 博文图片路径
          this.form.blogImagePath = this.filePathList.join(",");
          this.form.fileCode = this.fileCodeList.join(",");
          this.form.locale = "en"; // 默认为英文

          let method = "";
          let url = "";

          if (!this.form.id) {
            method = "post";
            url = blogInsertUrl;
          } else {
            method = "put";
            url = blogUpdateUrl;
          }

          let options = {
            method: method,
            data: this.form,
            url: url
          };


          this.$axios(options).then(res => {
            if (res.success) {
              this.$message.success(res.message);
              this.onClose();
              this.$emit("refresh");
            }
          })
            .finally(() => {
              this.isLoading = false;
              this.onClose();
            });
        } else {
          this.$message.warning("请填写表单！");
          return false;
        }
      });
    }
  },
};
</script>

<style scoped></style>